import dynamic from 'next/dynamic';

const NavFooterLayout = dynamic(
	() => import('../components/Layouts/NavFooterLayout')
);
const TradePageGridAndCustomiser = dynamic(
	() => import('../components/TradePageGrid/TradePageGridAndCustomiser')
);

const Index = () => {
	return (
		<NavFooterLayout currentPage="perpetuals" overflowAuto hideScroll={false}>
			<TradePageGridAndCustomiser />
		</NavFooterLayout>
	);
};

export default Index;
